import "react-app-polyfill/stable";
import "./index.css";
import "moment/locale/en-gb";
import "moment/locale/en-au";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/de";
import * as React from "react";
import { componentWithChildren } from "react-helper";
import { createRoot } from "react-dom/client";
import redux from "./modules/store";
import { App } from "./App";
import { ThemeProvider, themes } from "hcss-components";
import { BaseTheme } from "hcss-core";
import { Provider } from "react-redux";
import { initializeFontAwesome } from "./icons";
import { unregister } from "./registerServiceWorker";
import { PrintProvider as _PrintProvider } from "hcss-print";
import { Router as _Router } from "react-router-dom";
import { OidcProvider as _OidcProvider } from "./modules/account/services/oidc-context";
import { initializeConsoleOverride } from "console-override";
import {
  initializeAppInsights,
  history,
  reactPlugin,
  initializeDatadogRum
} from "logging";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { AppErrorRedirect } from "core/components/app-error-redirect";
import { SandboxLoader } from "mirage";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from "@mui/material/styles";
import { ThemeOptions } from "@mui/material/styles/createTheme";
import { isSandboxEnabled } from "core";
import { SandboxErrorRedirect } from "core/components/sandbox-error-redirect";

const theme = { ...themes.preConstruction, ...BaseTheme };

const muiTheme = createTheme({
  typography: {
    fontSize: 20
  },
  warningCustom: {
    main: "rgb(255, 244, 229)"
  }
} as ThemeOptions);

initializeConsoleOverride();
initializeFontAwesome();
initializeAppInsights();
initializeDatadogRum();

const OidcProvider = componentWithChildren(_OidcProvider);
const Router = componentWithChildren(_Router);
const PrintProvider = componentWithChildren(_PrintProvider);

const Root: React.FC = () => {
  const HandleError = () => {
    if (isSandboxEnabled()) {
      return <SandboxErrorRedirect />;
    }
    return <AppErrorRedirect />;
  };

  return (
    <AppInsightsErrorBoundary appInsights={reactPlugin} onError={HandleError}>
      <Router history={history}>
        <SandboxLoader>
          <OidcProvider>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={muiTheme}>
                <PrintProvider>
                  <Provider store={redux.store}>
                    <App />
                  </Provider>
                </PrintProvider>
              </MuiThemeProvider>
            </ThemeProvider>
          </OidcProvider>
        </SandboxLoader>
      </Router>
    </AppInsightsErrorBoundary>
  );
};

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<Root />);
unregister();
